import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This hook allows you to track whether an element is active when we press a specific key.
 * @param keypress keypress to monitor
 * @param ref ref to element to monitor
 * @param duration time before consider key as idle
 * @returns the current activity status (isKeyPressedWithinActiveElement)
 */
export const useKeyPressWithinActiveElement = (
  keypress: string,
  ref: React.RefObject<HTMLElement>,
  duration = 3000,
): boolean => {
  const [isKeyPressedWithinActiveElement, setIsKeyPressedWithinActiveElement] =
    useState(false);
  const timeoutId = useRef<number>();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      const handleTimeout = (): void =>
        setIsKeyPressedWithinActiveElement(false);

      // if match the key we want && focus is within ref
      if (e.key === keypress && ref.current?.contains(document.activeElement)) {
        setIsKeyPressedWithinActiveElement(true);
      }

      window.clearTimeout(timeoutId.current);
      timeoutId.current = window.setTimeout(handleTimeout, duration);
    },
    [duration, keypress, ref],
  );

  useEffect(() => {
    const currentRef = ref.current;
    currentRef?.addEventListener('keydown', handleKeyDown);

    return (): void => {
      currentRef?.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, keypress, ref]);

  return isKeyPressedWithinActiveElement;
};
