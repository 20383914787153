import { useEffect, useState } from 'react';

import { PlayerStates } from '@canalplus/oneplayer-constants';
import { TPlayerStates } from '@canalplus/oneplayer-types';

const { PLAYING, PAUSED, STOPPED, ENDED } = PlayerStates;

/**
 * This hooks allow you to identify of the playing status of the player has changed.
 * @param minimalPlayer minimal player instance
 * @returns a boolean indicating if the player is in a playing state
 */
export const useMinimalPlayerIsPlaying = (minimalPlayer: any): boolean => {
  const [isPlayingState, setIsPlayingState] = useState<boolean>(false);

  useEffect(() => {
    const onPlayerStateChange = (playerState: TPlayerStates): void => {
      switch (playerState) {
        case PLAYING:
          setIsPlayingState(true);
          break;
        case PAUSED:
        case STOPPED:
        case ENDED:
          setIsPlayingState(false);
          break;
        default:
          break;
      }
    };

    if (minimalPlayer) {
      setIsPlayingState(minimalPlayer.getPlayerState() === PLAYING);
      minimalPlayer.addEventListener('playerStateChange', onPlayerStateChange);
    }

    return (): void => {
      minimalPlayer?.removeEventListener(
        'playerStateChange',
        onPlayerStateChange,
      );
    };
  }, [minimalPlayer]);

  return isPlayingState;
};
