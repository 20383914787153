import { useContext } from 'react';
import classnames from 'classnames';

import { MinimalPlayerContext } from '../../context';
import { useAriaLabels } from '../../hooks';
import { ExitControlButton } from '../buttons/controls/exit/ExitControlButton';
import { SoundControlButton } from '../buttons/controls/sound/SoundControlButton';

import styles from './MiniTopControls.css';

interface IDefaultProps {
  shouldShowExitButton: boolean;
}

export interface IProps extends IDefaultProps {
  onExit?: () => void | undefined;
  onMute?: () => void | undefined;
  onUnmute?: () => void | undefined;
}

const MiniTopControls = ({
  shouldShowExitButton = false,
  onExit,
  onMute,
  onUnmute,
}: IProps): JSX.Element | null => {
  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const ariaLabels = useAriaLabels(minimalPlayer, ['Close']);

  if (!minimalPlayer) {
    return null;
  }

  return (
    <div className={styles.miniTopControls}>
      <div className={styles.controlsWrapTop}>
        {shouldShowExitButton && onExit && (
          <ExitControlButton onClick={onExit} ariaLabel={ariaLabels.Close} />
        )}
        <div className={classnames(styles.rightAlignedControl)}>
          <SoundControlButton onMute={onMute} onUnmute={onUnmute} />
        </div>
      </div>
    </div>
  );
};

export { MiniTopControls };
