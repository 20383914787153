import { useEffect, useState } from 'react';

import { throttle } from '@canalplus/oneplayer-utils';

const activityWindowEvents = [
  'mousemove',
  'mousedown',
  'resize',
  'keydown',
  'touchstart',
  'touchmove',
  'touchend',
  'wheel',
];

/**
 * This hook is a tool for detecting user inactivity within a web application.
 * It tracks user interaction and determines if a specified duration of time has passed without any activity.
 * (strongly inspired from https://usehooks.com/)
 * @param duration duration in milliseconds of the time passed since last activity
 * @returns a boolean that determines if the time passed since last activity passed the duration given
 */
export const useIdle = (duration: number): boolean => {
  const [idle, setIdle] = useState(false);

  useEffect(() => {
    let timeoutId: number;

    const handleTimeout = (): void => {
      setIdle(true);
    };

    const handleEvent = throttle(() => {
      setIdle(false);

      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(handleTimeout, duration);
    }, 500);

    const handleVisibilityChange = (): void => {
      if (!document.hidden) {
        handleEvent();
      }
    };

    timeoutId = window.setTimeout(handleTimeout, duration);

    activityWindowEvents.forEach((event) =>
      window.addEventListener(event, handleEvent),
    );
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return (): void => {
      activityWindowEvents.forEach((event) =>
        window.removeEventListener(event, handleEvent),
      );
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.clearTimeout(timeoutId);
    };
  }, [duration]);

  return idle;
};
