import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { secToFormatHours } from '@canalplus/oneplayer-utils';

import { MinimalPlayerContext } from '../../context';
import { useAriaLabels } from '../../hooks';
import { Slider } from '../slider/Slider';

import styles from './WebMiniProgressBar.css';

const {
  VARIANTS: { MYCANAL },
} = BusinessTypes;

export interface IProps {
  variant?: string;
  onSeek?: () => void | undefined;
}

/**
 *
 * @param arg0 WebMiniProgressBar props
 * @param arg0.variant the variant
 * @param arg0.onSeek callback to execute when 'seek' action is requested
 * @returns progress bar which is synchronised with the player used and allows the user to
 * navigate into the stream.
 */
const WebMiniProgressBar = ({
  variant = MYCANAL,
  onSeek,
}: IProps): JSX.Element | null => {
  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const ariaLabels = useAriaLabels(minimalPlayer, ['ProgressBar']);
  const [duration, setDuration] = useState<number | null>(
    Math.round(minimalPlayer?.getVideoDuration()) || null,
  );
  const [currentPosition, setCurrentPosition] = useState<number | null>(
    Math.round(minimalPlayer?.getPosition()) || null,
  );
  const isDraggingRef = useRef<boolean>(false);
  const sliderRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (minimalPlayer) {
      setDuration(Math.round(minimalPlayer.getVideoDuration()));
    }
  }, [minimalPlayer]);

  useEffect(() => {
    const updatePosition = ({ position }: { position: number }): void => {
      if (!isDraggingRef.current) {
        setCurrentPosition(Math.round(position));
      }
    };
    if (minimalPlayer) {
      minimalPlayer.addEventListener('positionUpdate', updatePosition);
    }
    return (): void => {
      minimalPlayer?.removeEventListener('positionUpdate', updatePosition);
    };
  }, [isDraggingRef, currentPosition, minimalPlayer]);

  const updateProgressBarStyle = useCallback(() => {
    if (duration !== null && currentPosition !== null && sliderRef.current) {
      const positionInPercentage = (currentPosition / duration) * 100;
      const actionColor = styles[`actionColor--${variant}`];
      const white = styles.colorWhite30;
      sliderRef.current.style.background = `linear-gradient(to right, ${actionColor} 0%, ${actionColor} ${positionInPercentage}%, ${white} ${positionInPercentage}%, ${white} 100%)`;
    }
  }, [duration, currentPosition, variant]);

  useEffect(() => {
    updateProgressBarStyle();
  }, [updateProgressBarStyle]);

  const onDrag = useCallback(
    (evt: React.FormEvent<HTMLInputElement>): void => {
      if (evt.currentTarget) {
        setCurrentPosition(parseInt(evt.currentTarget.value, 10));
      }
      updateProgressBarStyle();
    },
    [updateProgressBarStyle],
  );

  const onMouseDown = useCallback((): void => {
    isDraggingRef.current = true;
  }, []);

  const onMouseUp = useCallback((): void => {
    if (minimalPlayer) {
      isDraggingRef.current = false;
      onSeek?.();
      minimalPlayer.seekTo(currentPosition);
    }
  }, [minimalPlayer, onSeek, currentPosition]);

  if (!minimalPlayer || duration === null || currentPosition === null) {
    return null;
  }

  return (
    <div className={styles.progressBarWrapper}>
      <div className={styles.position}>{secToFormatHours(currentPosition)}</div>
      <Slider
        ariaLabel={ariaLabels.ProgressBar}
        ref={sliderRef}
        minValue={0}
        maxValue={duration}
        value={currentPosition}
        onDrag={onDrag}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        isTv={false}
      />
      <div className={styles.duration}>
        {secToFormatHours(duration - currentPosition)}
      </div>
    </div>
  );
};

export { WebMiniProgressBar };
